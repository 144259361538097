const version = "v2";
export const APP_API_URL = process.env.REACT_APP_API_URL;
export const PARCEL_MS_URL = process.env.REACT_APP_PARCEL_URL;
export const PAYMENT_MS_URL = process.env.REACT_APP_PAYMENT_MS_URL;
export const AUTH_MS_URL = process.env.REACT_APP_AUTH_API_URL;
export const ACCOUNTS_MS_URL = process.env.REACT_APP_ACCOUNTS_MS_URL;
export const RIDE_MS_URL = process.env.REACT_APP_RIDE_URL;
export const HIRING_MS_URL = process.env.REACT_APP_HIRING_URL;
export const GOOGLE_TAG_ID = process.env.REACT_APP_GOOGLE_TAG_ID;

export const COOKIES_SECURED = process.env.REACT_APP_COOKIES_SECURED;

export const RouteConstants = {
  BUS_HIRING: `/hiring_request`,
  BUS_FEATURES: `/${version}/bus-features/bus-features`,
  BUS_HIRING_RETURN_TYPES: `/trip_return_type/all`,
  TRANSACTIONS: `/${version}/transactions`,
  BUS_PAYMENTS: `${version}/payments`,
  ROUTES: `/${version}/routes`,
  LOADING_POINTS: `/${version}/loading-points`,
  RIDE_MS_WEBHOOKS: `/${version}/webhooks/payments`,
  ACCIDENTS: `/${version}/accidents`,
  ME: `/${version}/me`,
  PACKAGE: `/packages`,
  AUTH: `/${version}/auth`,
  TICKET: `/${version}/tickets`,
};
