import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import "react-phone-number-input/style.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { IntlProvider } from "react-intl";
import localeIntl from "./assets/intl/data.json";
import { GlobalDebug } from "./utils/remove-consoles";
import { requestPermission, onMessageListener } from "./utils/firebase";
import NotificationContext from "./utils/context/NotificationContext";
import Cookies from "js-cookie";
import { notification, Space, Button } from "antd";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { axius } from '../../utils'
import { Helmet } from "react-helmet";

// import * as authAct from './store/auth/_authActions'
// import * as utilsAct from './store/utils/_utilsActions'

import { Loading } from "./components";
import { useDispatch, useSelector } from "react-redux";
import {
  getCookie,
  requestInterceptor,
  responseInterceptor,
  setCookie,
} from "./utils/functions";
import { signOutSuccess, getUser } from "./store/auth/_authActions";

// Google Analytics
import ReactGA from "react-ga";
import { func } from "./utils";
import { GOOGLE_TAG_ID } from "./utils/constants";
// import { requestPermission } from './utils/firebase' // registerServiceWorker
// import { axius } from './utils'
const TRACKING_ID = "UA-244272523-1"; // OUR_TRACKING_ID

// ::: main screens
const App = React.lazy(() => import("./screens/App"));
const Ticket = React.lazy(() => import("./screens/App/Ticket"));
const DelegatesOverview = React.lazy(() =>
  import("./screens/App/Hire/Delegates/Overview")
);
const DelegatesEnroll = React.lazy(() =>
  import("./screens/App/Hire/Delegates/Enroll")
);
const DelegatesSignin = React.lazy(() =>
  import("./screens/App/Hire/Delegates/Signup")
);

const DelegatesSignup = React.lazy(() =>
  import("./screens/App/Hire/Delegates/Signin")
);

const routes = [
  { path: "/*", component: App, auth: true, exact: true },
  { path: "/ticket", component: Ticket, exact: true, auth: "any" },
  {
    path: "/delegates-overview",
    component: DelegatesOverview,
    exact: true,
    auth: "any",
  },
  {
    path: "/delegates-enroll",
    component: DelegatesEnroll,
    exact: true,
    auth: "any",
  },
  {
    path: "/delegates-signin",
    component: DelegatesSignin,
    exact: true,
    auth: "any",
  },
  {
    path: "/delegates-signup",
    component: DelegatesSignup,
    exact: true,
    auth: "any",
  },
];

const LoadingHTML = () => (
  <div
    className="text-center flex-middle"
    style={{ width: "100%", height: "100vh", justifyContent: "center" }}
  >
    <Loading text="starting app..." />
  </div>
);

axios.defaults.withCredentials = false;
axios.interceptors.request.use(requestInterceptor);
axios.interceptors.response.use(responseInterceptor);

const OyaGhana = () => {
  ReactGA.initialize(TRACKING_ID);

  const dispatch = useDispatch();
  const lang = useSelector((state) => state.utils.lang);
  const authenticated = Cookies.get("oya_token");
  const deviceID = Cookies.get("device_id");
  const userId = Cookies.get("oya_usr");
  const [notifications, setNotifications] = useState([]);

  const getFCMToken = () => {
    requestPermission()
      .then(
        async (token) => {
          const endPoints = [
            {
              url: `${process.env.REACT_APP_API_URL}v2/notifications/push/subscribe`,
              body: { token },
            },
            {
              url: `${process.env.REACT_APP_BUS_RIDE_URL}/v2/subscribe`,
              body: { handle: token },
            },
            {
              url: `${process.env.REACT_APP_PARCEL_URL}/subscribe`,
              body: { handle: token },
            },
          ];
          const response = await Promise.all(
            endPoints.map((eachEndPoint) => {
              return axios.post(eachEndPoint.url, eachEndPoint.body);
            })
          );
          if (response) {
            setCookie("device_id", token);
            console.log(token);
          }
        },
        (err) => {
          console.error("Error accepting/registering device:", err);
        }
      )
      .catch((err) => {
        console.error("An error occured:", err);
      });
  };

  // useEffect(() => {
  //   if (!deviceID) {
  //     getFCMToken();
  //   }
  // }, [deviceID]);

  useEffect(() => {
    // Fetch user details
    dispatch(getUser());

    if (
      !authenticated &&
      ![
        "/ticket",
        "/delegates-overview",
        "/delegates-enroll",
        "/delegates-signup",
        "/delegates-signin",
      ].includes(location.pathname)
    ) {
      dispatch(signOutSuccess());
    }

    // Remove all console prints in non-development platforms
    if (process.env.NODE_ENV !== "development") GlobalDebug(false, true);

    // Register service worker
    // registerServiceWorker()
  }, []);

  // useEffect(() => {
  //   onMessageListener()
  //     .then((payload) => {
  //       console.log(payload);
  //       setNotifications((prevState) => [
  //         {
  //           title: payload.notification?.title,
  //           body: payload.notification?.body,
  //         },
  //         ...prevState,
  //       ]);
  //     })
  //     .catch((err) => console.log("failed: ", err));
  // });

  const contextValue = {
    notifications,
    changeNotifications: setNotifications,
  };

  const queryClient = new QueryClient();

  return (
    <React.Fragment>
      <QueryClientProvider client={queryClient}>
        {/* <NotificationContext.Provider value={contextValue}> */}
        <React.Suspense fallback={<LoadingHTML />}>
          {/* <IntlProvider
              locale={lang}
              defaultLocale="en"
              messages={localeIntl[lang]}
            >
              
            </IntlProvider> */}
          <Router>
            <div>
              {/* Google Analytics */}
              <Helmet>
                <script
                  async
                  src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_TAG_ID}`}
                ></script>
                <script>
                  {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', ${GOOGLE_TAG_ID});
            `}
                </script>
              </Helmet>

              <Routes>
                {routes.map((row) => (
                  <Route
                    key={row.path}
                    path={row.path}
                    element={<row.component />}
                  />
                ))}
              </Routes>
            </div>
          </Router>
        </React.Suspense>
        {/* </NotificationContext.Provider> */}
      </QueryClientProvider>
    </React.Fragment>
  );
};

OyaGhana.propTypes = {
  _auth: PropTypes.object,
  _utils: PropTypes.object,
};
export default OyaGhana;
